<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>{{$route.meta.title}}</h1>
    </div>
    <br />
    <DataGridV2 :config="dataGridConfig" :data="dataSourceTable.data"  @actions="dataGridActions" ref="dataTable">
      <div class="row">
        <div class="col-sm-2">
          <button class="btn warning-btn" @click="loadUsuarios()">Recargar</button>
        </div>
        <div class="col-sm-2">
          <button  class="btn secondary-btn" :disabled="!usuario" @click="openModalResetPassword = true">Resetear contraseña</button>
        </div>
      </div>

    </DataGridV2>

    <Modal v-if="openModalResetPassword" :options="{width: '50vw'}" >
      <div class="title">Resetear contraseña de usuario</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Se restablecerá la contraseña del usuario: <strong>{{ usuario.nombre_completo }}</strong> y se le enviará un correo de notificación con sus nuevos datos de acceso.
            <br/>¿Esta seguro que desea continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-4 offset-sm-4"><button class="btn secondary-btn" type="button" @click="resetPassword">Continuar</button></div>
          <div class="col-sm-4"><button class="btn danger-btn" type="button" @click="openModalResetPassword =  false">Cancelar</button></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import DataGridV2 from '@/components/DataGridV2'
import Modal from '@/components/Modal'
import api from '@/apps/cobranza_admin/api/usuarios.api'


export default {
  name: "Listas",
  data(){
    return {
      openModalResetPassword: false,
      dataGridConfig: {
        name: 'usuarios_cobranza',
        cols: {
          nombre_completo: 'Nombre',
          email: 'Correo',
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          updated_at: function(val) {
            //return this.$moment(val).format('YYYY-MM-DD HH:mm');
            return val;
          }
        },
        search: true,
        select_items: true
      },
      options: {
        page: 1,
        order_col: 'id',
        order_dir: 'desc',
        limit: 20,
        filters: []
      },
      dataSourceTable: [],
      usuario: null,
    }
  },
  components: {
    DataGridV2,
    Modal,
  },
  methods: {
    dataGridActions: function(tipo, valor) {
      if (tipo === 'options'){
        this.options = valor;
        this.loadUsuarios();
      }

      if (tipo === 'selected') {
        this.usuario =  valor.length ? valor[0] : null;
      }
    },

    async loadUsuarios() {
        let query = this.options;
        let dataSource = await api.usuarios(query)
        this.dataSourceTable = dataSource.data;
        this.dataGridConfig.paginator.pagina_actual = this.dataSourceTable.current_page;
        this.dataGridConfig.paginator.total_registros = this.dataSourceTable.total;
        this.dataGridConfig.paginator.registros_por_pagina = parseInt(this.dataSourceTable.per_page);
    },
    async resetPassword(){
      this.openModalResetPassword = false;
      await api.resetPassword(this.usuario.id).then(response => {
        this.$helper.showMessage('Resetear contraseña de usuario','Se le enviará un correo de notificación al usuario con sus nuevos datos de acceso.', 'success', 'alert');
      });
    }
  }
}
</script>

<style scoped lang="scss">
.btn-detalle{
  font-family: "NunitoRegular";
  padding: 0.5rem !important;
}
.btn-detalle:hover{
  background: transparent;
  color: #545b62;
}
</style>
