import adminApi from "./admin.api";
import qs from 'qs'

export default {
    /**
     * Consulta y filtra registros de usuarios
     * @param {Object} query
     * @returns {Promise}
     */
    usuarios(query= {}) {
        return adminApi.api.get(`${adminApi.baseURL}/usuarios?${qs.stringify(query)}`)
    },

    /**
     * Resetear contraseña de usuarios
     * @param usuario: id de usuario
     * @returns {Promise}
     */
    resetPassword(usuario){
        return adminApi.api.get(`${adminApi.baseURL}/usuarios/${usuario}/password_reset`)
    }

}
